.action {
  display: flex;
  justify-content: space-between;
}
.action .primary {
  width: 50%;
  font-size: 2.2rem;
  font-family: 'Pocket Monk';
  letter-spacing: 5px;
  font-weight: 100;
  padding-top: 5px;
  padding-bottom: 5px;
}
.action .secondary {
  background: transparent;
  color: black;
  width: 30%;
  font-size: 1.5rem;
  font-family: 'Pocket Monk';
  letter-spacing: 3px;
  font-weight: 100;
}
.action .secondary:hover {
  background: #ffc0cd;
}
