.root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(252, 161, 86, 0.1);
  backdrop-filter: blur(10px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  z-index: 100000;
}

.root.open {
  opacity: 1;
  visibility: visible;
}

.modal {
  width: 600px;
  min-height: 110px;
  display: block;
  margin: 50% 0 0 -300px;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fafafa;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  border-radius: 30px;
}

.root.open .modal {
  margin-top: -200px;
  opacity: 1;
}

.head {
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 64px;
  overflow: hidden;
  background: #01bce5;
  position: relative;
  padding: 12px 52px 12px 24px;
  font-size: 2.5rem;
  font-family: 'Pocket Monk';
  letter-spacing: 5px;
  font-weight: 100;
}

.btnClose {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.btnClose:hover::before,
.btnClose:hover::after {
  background-color: #f300b4;
}

.btnClose::before,
.btnClose::after {
  content: '';
  width: 32px;
  height: 6px;
  display: block;
  background: #fafafa;
}

.btnClose::before {
  margin-top: 12px;
  transform: rotate(45deg);
}

.btnClose::after {
  margin-top: -6px;
  transform: rotate(-45deg);
}

.content {
  padding: 12px 24px;
}
