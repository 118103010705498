button,
button:visited {
  width: 100%;
  margin-top: 10px;
  padding: 1rem;
  border: 1px solid #fafafa;
  color: #fafafa;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  transition: all ease-in-out 0.25s;
  cursor: pointer;
  box-sizing: border-box;
  background: purple;
  border-radius: 20px;
  font-weight: bold;
  outline: none;
}

button:hover {
  background: pink;
  border: 1px solid pink;
  color: black;
  transition: all ease-in-out 500ms;
  outline: none;
}

button:active {
  background: transparent;
  border: 1px solid #f300b4;
  color: #f300b4;
  transition: all ease-in-out 0s;
  outline: none;
  padding: 1.05rem 1rem calc(0.95rem - 1px) calc(1rem - 1px);
  border-top: 2px solid;
  border-right: 2px solid;
}

button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

button:disabled:hover {
  background: #f300b4;
  border: 1px solid transparent;
  color: #fafafa;
}
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pokemonCard {
  width: 268px;
  height: 388px;
  margin: 16px;
  perspective: 1000px;
}
