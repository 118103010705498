.root {
  position: fixed;
  z-index: 9999;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.root.bgActive {
  background: #181d23;
}

.root .navWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  padding: 0 2rem;
}

.root .brand {
  font-size: 1.6rem;
  color: #fafafa;
  cursor: default;
}

/***** Menu Button *****/
.menuButton {
  position: relative;
  height: 22px;
  width: 30px;
  outline: none;
}

.menuButton span,
.menuButton span::before,
.menuButton span::after {
  position: absolute;
  content: '';
  width: 30px;
  height: 3px;
  background: #fafafa;
  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
}

.menuButton span {
  position: relative;
  display: block;
  top: 50%;
  transform: translate(0, -50%);
}

.menuButton span::before {
  top: -8px;
}

.menuButton span::after {
  top: 8px;
}

.menuButton:hover > span,
.menuButton:hover > span::before,
.menuButton:hover > span::after {
  background: #f300b4;
}

.menuButton.active > span {
  background: transparent;
}

.menuButton.active > span::before {
  transform: rotate(-225deg);
  top: 0;
}

.menuButton.active > span::after {
  transform: rotate(225deg);
  top: 0;
}

@media only screen and (max-width: 849px) {
  #navbar {
    background: #181d23aa;
  }
}
.loginAndMenu {
  display: flex;
}
.loginWrap {
  width: 22px;
  height: 22px;
  margin-right: 12px;
}
.loginWrap svg {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.loginWrap svg path {
  fill: white;
  transition: fill ease-in-out 0.25s;
}
.loginWrap:hover svg path {
  fill: #f300b4;
}
.loginLogout {
  display: flex;
}
