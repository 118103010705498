@import url(https://fonts.googleapis.com/css?family=Overlock:400,400i,700|Oleo+Script);
*,
*::before,
*::after,
:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  color: #252934;
  background: transparent;
  font-size: 62.5%;
  font-family: 'Overlock', Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

a,
a:visited {
  color: #252934;
  font-size: 1.4rem;
  text-decoration: none;
  transition: 200ms;
}

a:hover,
a:active {
  color: #f300b4;
}
@font-face {
  font-family: Pocket Monk;
  src: url("/static/media/Pocket Monk.09d09837.otf") format('opentype');
}

button,
button:visited {
  width: 100%;
  margin-top: 10px;
  padding: 1rem;
  border: 1px solid #fafafa;
  color: #fafafa;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  transition: all ease-in-out 0.25s;
  cursor: pointer;
  box-sizing: border-box;
  background: purple;
  border-radius: 20px;
  font-weight: bold;
  outline: none;
}

button:hover {
  background: pink;
  border: 1px solid pink;
  color: black;
  transition: all ease-in-out 500ms;
  outline: none;
}

button:active {
  background: transparent;
  border: 1px solid #f300b4;
  color: #f300b4;
  transition: all ease-in-out 0s;
  outline: none;
  padding: 1.05rem 1rem calc(0.95rem - 1px) calc(1rem - 1px);
  border-top: 2px solid;
  border-right: 2px solid;
}

button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

button:disabled:hover {
  background: #f300b4;
  border: 1px solid transparent;
  color: #fafafa;
}
.styles_section__1qiKv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.styles_pokemonCard__39XCJ {
  width: 268px;
  height: 388px;
  margin: 16px;
  perspective: 1000px;
}

.style_root__3EDU_ {
  background: #202736;
  background: linear-gradient(to bottom, #181d23 0%, #202736 80%);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  margin: 0 auto;
  z-index: 1;
}

.style_root__3EDU_:before {
  content: '';
  position: fixed;
  background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Stars.svg?sanitize=true);
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
  opacity: 0;
  animation: style_stars-move-in__3cgo4 1000ms 300ms forwards;
}

@keyframes style_stars-move-in__3cgo4 {
  from {
    background-position-y: -100px;
  }
  to {
    opacity: 1;
    background-position-y: 0;
  }
}

.style_forest__2l_b9 {
  position: absolute;
  bottom: -300px;
  left: 0;
  background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Trees.svg?sanitize=true)
    bottom left repeat-x;
  background-size: cover;
  width: 100%;
  height: 80%;
  opacity: 0;
  animation: style_forest-move-in__qZA3m 1000ms 500ms forwards;
  border-bottom: 300px solid #181d23;
}

@keyframes style_forest-move-in__qZA3m {
  from {
    background-position-y: 150%;
  }
  to {
    opacity: 1;
    background-position-y: 100%;
  }
}

.style_silhouette__13n9s {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Silhouette.svg?sanitize=true)
    bottom left no-repeat;
  width: 50%;
  height: 50%;
  opacity: 0;
  animation: style_silhouette-move-in__3Avbb 1000ms 800ms forwards;
}

@keyframes style_silhouette-move-in__3Avbb {
  from {
    background-position-x: 0;
  }
  to {
    opacity: 1;
    background-position-x: 50%;
  }
}

.style_moon__2fHAp {
  top: 0;
  right: 0;
  position: fixed;
  background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Moon.svg?sanitize=true)
    right 150% no-repeat;
  background-size: 30% 30%;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  animation: style_moon-move-in__j_efo 1.2s 1s forwards;
}

@keyframes style_moon-move-in__j_efo {
  from {
    opacity: 0;
    background-position: right 150%;
  }
  to {
    opacity: 1;
    background-position: top right;
  }
}

.style_container__3TbXE {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  right: 0;
  top: 50%;
  left: 55%;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation: style_text-fade-in__32u_A 1000ms 800ms forwards;
}

@keyframes style_text-fade-in__32u_A {
  from {
    right: 0;
  }
  to {
    opacity: 1;
    right: 25%;
  }
}

.style_container__3TbXE h1 {
  font-size: 65px;
  font-weight: normal;
  font-style: italic;
  color: #fafafa;
  line-height: 65px;
}

.style_container__3TbXE p {
  font-size: 28px;
  font-weight: normal;
  color: #fafafa;
  line-height: 32px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 649px) {
  .style_container__3TbXE {
    right: 50%;
    top: 10%;
    width: 80%;
    transform: translate(50%, 0);
    animation: style_text-fade-in__32u_A 1000ms 800ms forwards;
  }

  @keyframes style_text-fade-in__32u_A {
    from {
      right: 0;
    }
    to {
      opacity: 1;
      right: 50%;
    }
  }

  .style_silhouette__13n9s {
    width: 100%;
  }
}

.style_root__3iurA {
  font-size: 1.4rem;
  position: relative;
  background: #fafafa;
  -webkit-clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 80% 95%, 0 100%);
          clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 80% 95%, 0 100%);
  z-index: 5;
  background-attachment: fixed;
}

.style_root__3iurA .style_wrapper__NNZoX {
  padding: 5rem 1rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.style_root__3iurA article {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  padding: 3rem 0;
}

.style_root__3iurA .style_title__3q_Ze {
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.style_root__3iurA .style_title__3q_Ze h3 {
  font-size: 2.8rem;
  font-family: 'Pocket Monk';
  letter-spacing: 5px;
  font-weight: 100;
}

.style_root__3iurA .style_separator__3Iwm3 {
  background: #f300b4;
  width: 150px;
  height: 2px;
  margin: 1rem 0;
  padding: 0;
}

.style_root__3iurA .style_subtitle__3TSsQ {
  font-size: 1.6rem;
  text-align: center;
  color: inherit;
  padding-bottom: 1.5rem;
}

.style_root__3iurA p {
  padding-bottom: 1.5rem;
  color: #555;
  line-height: 1.9rem;
}

.style_root__3iurA .style_desc__14pKG.style_full__3XGPX {
  grid-column-end: span 4;
  margin-bottom: 2rem;
}

.style_root__3iurA .style_desc__14pKG {
  grid-column-end: span 2;
  background: #ffffffd7;
  padding: 2rem;
  text-align: justify;
}

@media only screen and (max-width: 1149px) {
  .style_root__3iurA article {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .style_root__3iurA .style_desc__14pKG.style_full__3XGPX {
    grid-column-end: -1;
  }

  .style_root__3iurA .style_desc__14pKG {
    grid-column-end: -1;
  }
}
@media only screen and (max-width: 949px) {
  .style_root__3iurA {
    -webkit-clip-path: polygon(0 0, 20% 2%, 100% 0, 100% 100%, 80% 98%, 0 100%);
            clip-path: polygon(0 0, 20% 2%, 100% 0, 100% 100%, 80% 98%, 0 100%);
    background-position: top left;
    background-size: cover;
  }
}
@media only screen and (max-width: 649px) {
  .style_root__3iurA .style_wrapper__NNZoX {
    padding: 10rem 2rem 8rem;
  }
}

.styles_face__3vBIO {
  border-radius: 20% 5% 5% 20%;
}
.styles_wrap__2GjNL {
  display: flex;
  flex-direction: row;
}
.styles_textBlock__1OjC- {
  padding-left: 4rem;
}
.styles_name__1JaDn {
  font-family: 'Buckwheat';
  font-size: xxx-large;
}
@font-face {
  font-family: 'Buckwheat';
  src: url("/static/media/Buckwheat TC Sans Painted.35752214.otf") format('opentype'),
    url("/static/media/Buckwheat TC Sans SVG.63aff6ee.ttf") format('truetype');
}

.styles_menuContainer__glmh2 > .styles_overlay__3afRj,
.styles_menuContainer__glmh2.styles_active__uhflQ > .styles_overlay__3afRj {
  position: absolute;
  right: 0;
  height: calc(100vh - 120px);
  width: calc(100vw - 120px);
  background: #526999;
}

.styles_menuContainer__glmh2.styles_active__uhflQ > .styles_overlay__3afRj {
  animation: styles_overlay-slide-in__3quRG 300ms forwards 300ms;
}

@keyframes styles_overlay-slide-in__3quRG {
  from {
    width: calc(100vw - 120px);
  }
  to {
    width: 0;
  }
}

.styles_menuContainer__glmh2 > .styles_overlay__3afRj {
  animation: styles_overlay-slide-out__2xFTq 300ms forwards;
}

@keyframes styles_overlay-slide-out__2xFTq {
  from {
    left: 0;
    width: 0;
  }
  to {
    left: 0;
    width: calc(100vw - 120px);
  }
}

.styles_menuContainer__glmh2 {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #202934;
  border: 60px solid #181d23;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

.styles_menuContainer__glmh2::before,
.styles_menuContainer__glmh2::after {
  content: '';
  position: absolute;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
}

.styles_menuContainer__glmh2::before {
  background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Stars.svg?sanitize=true);
}

.styles_menuContainer__glmh2::after {
  background: url(https://raw.githubusercontent.com/yagoestevez/fcc-portfolio/master/src/Images/Trees.svg?sanitize=true)
    bottom repeat-x;
}

.styles_menuContainer__glmh2.styles_deactive__3D2eT {
  animation: styles_fade-out__3SmjC 600ms forwards;
}

@keyframes styles_fade-out__3SmjC {
  0% {
    opacity: 1;
    z-index: 999;
  }
  50% {
    opacity: 1;
    z-index: 999;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

.styles_menuContainer__glmh2.styles_active__uhflQ {
  animation: styles_fade-in__w8h02 300ms forwards;
}

@keyframes styles_fade-in__w8h02 {
  from {
    opacity: 0;
    z-index: -1;
  }
  to {
    opacity: 1;
    z-index: 999;
  }
}

/***** Menu Items: Animation *****/
.styles_menuContainer__glmh2 ul {
  margin-left: -80px;
  opacity: 0;
  animation: styles_slide-out__1cQ4o 200ms forwards;
  list-style-type: none !important;
  font-size: 3rem;
}

@keyframes styles_slide-out__1cQ4o {
  from {
    opacity: 1;
    margin-left: 0;
  }
  to {
    opacity: 0;
    margin-left: -80px;
  }
}

.styles_menuContainer__glmh2.styles_active__uhflQ ul {
  animation: styles_slide-in__17pMg 300ms forwards 600ms;
}

@keyframes styles_slide-in__17pMg {
  from {
    opacity: 0;
    margin-left: -80px;
  }
  to {
    opacity: 1;
    margin-left: 0;
  }
}

/***** Menu Items: Hover Animation *****/
.styles_menuContainer__glmh2 ul li {
  border-left: 0.2rem solid transparent;
  transition: border-left 200ms;
}

.styles_menuContainer__glmh2 ul li a {
  font-size: 3rem;
  padding-left: 0.5rem;
}

.styles_menuContainer__glmh2 ul li a::after {
  content: ' »';
  font-size: 2.5rem;
  color: transparent;
  transition: color 200ms;
}

.styles_menuContainer__glmh2 ul li a:hover::after {
  content: ' »';
  color: #f300b4;
}

.styles_menuContainer__glmh2 a,
.styles_menuContainer__glmh2 a:visited {
  color: #fafafa;
}

.styles_menuContainer__glmh2 a:hover,
.styles_menuContainer__glmh2 a:active {
  color: #f300b4;
}

@media only screen and (max-width: 649px) {
  .styles_menuContainer__glmh2 {
    border: none;
  }

  .styles_menuContainer__glmh2 > .styles_overlay__3afRj,
  .styles_menuContainer__glmh2.styles_active__uhflQ > .styles_overlay__3afRj {
    height: 100vh;
    width: 100vw;
  }

  .styles_menuContainer__glmh2.styles_active__uhflQ > .styles_overlay__3afRj {
    animation: styles_overlay-slide-in__3quRG 300ms forwards 300ms;
  }

  @keyframes styles_overlay-slide-in__3quRG {
    from {
      width: 100vw;
    }
    to {
      width: 0;
    }
  }

  .styles_menu-container__2OyfP > .styles_overlay__3afRj {
    animation: styles_overlay-slide-out__2xFTq 300ms forwards;
  }

  @keyframes styles_overlay-slide-out__2xFTq {
    from {
      left: 0;
      width: 0;
    }
    to {
      left: 0;
      width: 100vw;
    }
  }
}

.styles_root__2AmgV {
  position: fixed;
  z-index: 9999;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.styles_root__2AmgV.styles_bgActive__3kAWD {
  background: #181d23;
}

.styles_root__2AmgV .styles_navWrapper__tgTCy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  padding: 0 2rem;
}

.styles_root__2AmgV .styles_brand__2vrne {
  font-size: 1.6rem;
  color: #fafafa;
  cursor: default;
}

/***** Menu Button *****/
.styles_menuButton__2Axxy {
  position: relative;
  height: 22px;
  width: 30px;
  outline: none;
}

.styles_menuButton__2Axxy span,
.styles_menuButton__2Axxy span::before,
.styles_menuButton__2Axxy span::after {
  position: absolute;
  content: '';
  width: 30px;
  height: 3px;
  background: #fafafa;
  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
}

.styles_menuButton__2Axxy span {
  position: relative;
  display: block;
  top: 50%;
  transform: translate(0, -50%);
}

.styles_menuButton__2Axxy span::before {
  top: -8px;
}

.styles_menuButton__2Axxy span::after {
  top: 8px;
}

.styles_menuButton__2Axxy:hover > span,
.styles_menuButton__2Axxy:hover > span::before,
.styles_menuButton__2Axxy:hover > span::after {
  background: #f300b4;
}

.styles_menuButton__2Axxy.styles_active__2e5ix > span {
  background: transparent;
}

.styles_menuButton__2Axxy.styles_active__2e5ix > span::before {
  transform: rotate(-225deg);
  top: 0;
}

.styles_menuButton__2Axxy.styles_active__2e5ix > span::after {
  transform: rotate(225deg);
  top: 0;
}

@media only screen and (max-width: 849px) {
  #styles_navbar__O9QwO {
    background: #181d23aa;
  }
}
.styles_loginAndMenu__16Afi {
  display: flex;
}
.styles_loginWrap__3gGM3 {
  width: 22px;
  height: 22px;
  margin-right: 12px;
}
.styles_loginWrap__3gGM3 svg {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.styles_loginWrap__3gGM3 svg path {
  fill: white;
  transition: fill ease-in-out 0.25s;
}
.styles_loginWrap__3gGM3:hover svg path {
  fill: #f300b4;
}
.styles_loginLogout__mmaxj {
  display: flex;
}

.style_img__v9efe {
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 50;
  width: 3rem;
  border-radius: 50%;
}

.style_root__1QZjS {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(252, 161, 86, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  z-index: 100000;
}

.style_root__1QZjS.style_open__3PN-m {
  opacity: 1;
  visibility: visible;
}

.style_modal__2agqS {
  width: 600px;
  min-height: 110px;
  display: block;
  margin: 50% 0 0 -300px;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fafafa;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  border-radius: 30px;
}

.style_root__1QZjS.style_open__3PN-m .style_modal__2agqS {
  margin-top: -200px;
  opacity: 1;
}

.style_head__2ms9S {
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 64px;
  overflow: hidden;
  background: #01bce5;
  position: relative;
  padding: 12px 52px 12px 24px;
  font-size: 2.5rem;
  font-family: 'Pocket Monk';
  letter-spacing: 5px;
  font-weight: 100;
}

.style_btnClose__3rp36 {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.style_btnClose__3rp36:hover::before,
.style_btnClose__3rp36:hover::after {
  background-color: #f300b4;
}

.style_btnClose__3rp36::before,
.style_btnClose__3rp36::after {
  content: '';
  width: 32px;
  height: 6px;
  display: block;
  background: #fafafa;
}

.style_btnClose__3rp36::before {
  margin-top: 12px;
  transform: rotate(45deg);
}

.style_btnClose__3rp36::after {
  margin-top: -6px;
  transform: rotate(-45deg);
}

.style_content__1j4MX {
  padding: 12px 24px;
}

.style_root__12Hl4 {
  position: relative;
  margin-bottom: 25px;
  margin-top: 20px;
}

.style_input__2g-wE {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}

.style_input__2g-wE:focus {
  outline: none;
}

.style_label__1qSzG {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
}
.style_label__1qSzG:first-letter {
  text-transform: capitalize;
}
.style_input__2g-wE:focus ~ .style_label__1qSzG,
.style_input__2g-wE:valid ~ .style_label__1qSzG,
.style_input__2g-wE.style_valid__KXqTm ~ .style_label__1qSzG {
  top: -20px;
  font-size: 14px;
  color: #f300b4;
}

.style_bar__3qg3N {
  position: relative;
  display: block;
  width: 100%;
}

.style_bar__3qg3N:before,
.style_bar__3qg3N:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #f300b4;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.style_bar__3qg3N:before {
  left: 50%;
}

.style_bar__3qg3N:after {
  right: 50%;
}

.style_input__2g-wE:focus ~ .style_bar__3qg3N:before,
.style_input__2g-wE:focus ~ .style_bar__3qg3N:after {
  width: 50%;
}

.style_highlight__139Cg {
  position: absolute;
  height: 60%;
  width: 80%;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.25;
}

.style_input__2g-wE:focus ~ .style_highlight__139Cg {
  animation: style_inputHighlighter__39Kfw 0.3s ease;
}

@keyframes style_inputHighlighter__39Kfw {
  from {
    background: #f300b4;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.style_action__3dm90 {
  display: flex;
  justify-content: space-between;
}
.style_action__3dm90 .style_primary__2O5DO {
  width: 50%;
  font-size: 2.2rem;
  font-family: 'Pocket Monk';
  letter-spacing: 5px;
  font-weight: 100;
  padding-top: 5px;
  padding-bottom: 5px;
}
.style_action__3dm90 .style_secondary__aqYrw {
  background: transparent;
  color: black;
  width: 30%;
  font-size: 1.5rem;
  font-family: 'Pocket Monk';
  letter-spacing: 3px;
  font-weight: 100;
}
.style_action__3dm90 .style_secondary__aqYrw:hover {
  background: #ffc0cd;
}

footer {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: #fafafa;
  z-index: 0;
}

footer .style_wrapper__2bQcZ {
  display: flex;
  width: 100%;
  padding: 2rem;
  max-width: 1400px;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 649px) {
  footer .style_wrapper__2bQcZ {
    flex-direction: column;
  }

  footer .style_wrapper__2bQcZ h3 {
    padding-bottom: 0.8rem;
  }
}

.styles_wrap__3agnE {
  min-height: calc(100vh-100px);
  padding: 96px 32px 24px;
}
.styles_wrap__3agnE.styles_isHomePage__SSbY5 {
  padding: 0;
}

.style_pokemonCard__8ZvSx {
  --fire: #fddfdf;
  --grass: #defde0;
  --electric: #fcf7de;
  --water: #def3fd;
  --ground: #f4e7da;
  --rock: #d5d5d4;
  --fairy: #fceaff;
  --poison: #98d7a5;
  --bug: #f8d5a3;
  --dragon: #97b3e6;
  --psychic: #eaeda1;
  --flying: #f5f5f5;
  --fighting: #e6e0d4;
  --normal: #f5f5f5;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: 0.6s 0.1s;
  transform: rotateY(180deg);
}

.style_pokemonCard__8ZvSx.style_active__1Ws21 {
  transform: rotateY(0deg);
}

@keyframes style_flipFront__9tfbt {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.style_pokemonCard__8ZvSx.style_selected__1WY-R {
  margin-top: -40px;
}

.style_cardFront__2YrLu,
.style_cardBack__13iOl {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: var(--normal);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_cardFront__2YrLu {
  transform: rotateY(0deg);
  z-index: 2;
}

.style_wrap__3Pchb {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.style_wrap__3Pchb.style_front__37dBN {
  z-index: 2;
  padding: 10px;
}

.style_wrap__3Pchb.style_front__37dBN::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 12px solid #fce756;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
}

.style_wrap__3Pchb.style_back__3vnDg {
  z-index: -1;
  background-image: url(/static/media/card-back-side.3bc67f21.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.style_cardBack__13iOl {
  transform: rotateY(180deg);
  z-index: 1;
}

.style_cardBack__13iOl img {
  height: 100%;
  border-radius: 24px;
}

.style_pokemon__3XkM5 {
  width: 100%;
  height: 100%;
  background-color: #eee;
  border-radius: 20px;
  box-shadow: 0 3px 15px rgba(100, 100, 100, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.style_fire__4Ewzg {
  background-color: var(--fire);
}
.style_grass__1_-6_ {
  background-color: var(--grass);
}
.style_electric__2vMHc {
  background-color: var(--electric);
}
.style_water__3OXYO {
  background-color: var(--water);
}
.style_ground__2O8F0 {
  background-color: var(--ground);
}
.style_rock__368z_ {
  background-color: var(--rock);
}
.style_fairy__2utDC {
  background-color: var(--fairy);
}
.style_poison__3CkeB {
  background-color: var(--poison);
}
.style_bug__3SoKj {
  background-color: var(--bug);
}
.style_dragon__1ndfA {
  background-color: var(--dragon);
}
.style_psychic__1G8FQ {
  background-color: var(--psychic);
}
.style_flying__1KRo4 {
  background-color: var(--flying);
}
.style_fighting__34PS_ {
  background-color: var(--fighting);
}
.style_normal__1U4li {
  background-color: var(--normal);
}

.style_pokemon__3XkM5 .style_imgContainer__A5Yeq {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  width: 140px;
  height: 140px;
  text-align: center;
  margin-bottom: 20px;
}

.style_pokemon__3XkM5 .style_imgContainer__A5Yeq img {
  margin-top: 20px;
  max-width: 90%;
}

.style_pokemon__3XkM5 .style_info__2Iru8 {
  margin-top: 20px;
}

.style_pokemon__3XkM5 .style_number__36aC9 {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 0.8em;
  padding: 5px 10px;
}

.style_pokemon__3XkM5 .style_name__1PM2A {
  margin: 15px 0 7px;
  letter-spacing: 1px;
}

.style_values__2YYnw {
  position: absolute;
  display: block;
  top: 20px;
  left: 20px;
  font-size: 26px;
  line-height: 26px;
  width: 78px;
  height: 78px;
}

.style_count__3Dflr {
  position: absolute;
  width: 30px;
  height: 26px;
  text-align: center;
}

.style_count__3Dflr.style_top__mOtmU {
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
}

.style_count__3Dflr.style_left__1Rk5N {
  top: 26px;
  left: 0;
}

.style_count__3Dflr.style_right__pqciI {
  top: 26px;
  right: 0;
}

.style_count__3Dflr.style_bottom__17ped {
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
}
.style_red___jwBy {
  background-color: #d998b2;
}
.style_blue__2CzqT {
  background-color: #a8caea;
}

.styles_section__1-sbU {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.styles_pokemonCard__cTahL {
  width: 268px;
  height: 388px;
  margin: 16px;
  perspective: 1000px;
}

.style_root__31q2h {
  background-image: url(/static/media/board.73af5dd5.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vw / 1.71);
  position: relative;
}

.style_board__242EF {
  display: grid;
  grid-template-columns: auto auto auto;
  position: absolute;
  width: 50.3vw;
  height: 50.3vw;
  top: 4.3vw;
  left: 25.1vw;
}

.style_boardPlate__sSFIh {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  cursor: pointer;
  font-size: 64px;
}
.style_boardPlate__sSFIh:hover {
  background: rgba(255, 255, 255, 0.1);
}
.style_boardPlate__sSFIh.style_disabled__1ZLHw:hover {
  background: none;
}

.style_card__2ic25 {
  max-height: 14.8vw;
  margin-top: -6vw;
}

.style_card__2ic25:first-child {
  margin-top: 0;
}
.style_playerOne__3vi__ {
  position: absolute;
  top: 4.3vw;
  left: 5vw;
  width: 18vw;
  height: 50.3vw;
}
.style_playerTwo__184oU {
  position: absolute;
  top: 4.3vw;
  right: 5vw;
  width: 18vw;
  height: 50.3vw;
}

.styles_hand__3eGrW {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 18vw;
  max-height: 14.8vw;
  margin-top: -6vw;
  z-index: 1;
}
.styles_hand__3eGrW:first-child {
  margin-top: 0;
}
.styles_hand__3eGrW.styles_isSelected__WHMZA {
  margin-left: -20px;
  z-index: 2;
}

.style_result__pHaDH {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.style_arrow__3k9u6 {
  position: absolute;
  top: 50%;
  left: 50%;
  translatey: -50%;
  margin: -60px 0 0 -60px;
  width: 0;
  height: 0;
  /* Triangle Borders */
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-left: 100px solid yellow;
  animation: style_spin__3Wqzj 1s linear infinite;
}

.style_rightSide__1lmaH {
  animation: none;
  transform: rotateY(0deg);
}

.style_leftSide__29W2M {
  animation: none;
  transform: rotateY(180deg);
}

@keyframes style_spin__3Wqzj {
  100% {
    transform: rotateY(360deg);
  }
}

.styles_pokemonCard__3Eioy {
  width: 268px;
  height: 388px;
  margin: 16px;
  perspective: 1000px;
}
.styles_row__Fu2rV {
  display: flex;
  width: 100%;
  max-width: 100vw;
  justify-content: center;
}

.style_table__1Wg58 {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  border-collapse: collapse;
  border: 2px solid purple;
}
table,
th,
td {
  border: 1px dotted rgb(78, 78, 78);
  word-break: break-all;
}
thead th:nth-child(1) {
  width: 30%;
}
thead th:nth-child(2) {
  width: 60%;
}
td {
  padding: 3px;
}
.style_row__hV3Zj {
  font-size: 20px;
  text-transform: capitalize;
}
.style_header__2gYq4 {
  font-size: 50px;
  font-family: 'Consolas';
}

