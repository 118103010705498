.pokemonCard {
  width: 268px;
  height: 388px;
  margin: 16px;
  perspective: 1000px;
}
.row {
  display: flex;
  width: 100%;
  max-width: 100vw;
  justify-content: center;
}
