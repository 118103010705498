.hand {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 18vw;
  max-height: 14.8vw;
  margin-top: -6vw;
  z-index: 1;
}
.hand:first-child {
  margin-top: 0;
}
.hand.isSelected {
  margin-left: -20px;
  z-index: 2;
}
